/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addProductAssetTypePairRequest = /* GraphQL */ `
  query AddProductAssetTypePairRequest(
    $asset_type: String!
    $client_id: String!
    $product_id: String!
  ) {
    AddProductAssetTypePairRequest(
      asset_type: $asset_type
      client_id: $client_id
      product_id: $product_id
    ) {
      _empty
    }
  }
`;
export const addWSEditableUserRequest = /* GraphQL */ `
  query AddWSEditableUserRequest(
    $editable_user_id: String!
    $workspace_id: String!
  ) {
    AddWSEditableUserRequest(
      editable_user_id: $editable_user_id
      workspace_id: $workspace_id
    ) {
      _empty
    }
  }
`;
export const createPolicyAssetMixRequest = /* GraphQL */ `
  query CreatePolicyAssetMixRequest(
    $client_id: String!
    $policy_asset_mix_name: String!
  ) {
    CreatePolicyAssetMixRequest(
      client_id: $client_id
      policy_asset_mix_name: $policy_asset_mix_name
    ) {
      id
    }
  }
`;
export const deletePolicyAssetMixRequest = /* GraphQL */ `
  query DeletePolicyAssetMixRequest($policy_asset_mix_id: String!) {
    DeletePolicyAssetMixRequest(policy_asset_mix_id: $policy_asset_mix_id) {
      _empty
    }
  }
`;

export const createPortfolioRequest = /* GraphQL */ `
  query CreatePortfolioRequest(
    $portfolio_name: String!
    $portfolio_set_id: String!
    $proposal: Boolean!
  ) {
    CreatePortfolioRequest(
      portfolio_name: $portfolio_name
      portfolio_set_id: $portfolio_set_id
      proposal: $proposal
    ) {
      id
    }
  }
`;
export const createPortfolioWorkspaceRequest = /* GraphQL */ `
  query CreatePortfolioWorkspaceRequest(
    $client_id: String!
    $policy_asset_mix_id: String!
    $workspace_name: String!
    $factor_year: Int!
    $factor_revision: Int!
  ) {
    CreatePortfolioWorkspaceRequest(
      client_id: $client_id
      policy_asset_mix_id: $policy_asset_mix_id
      workspace_name: $workspace_name
      factor_year: $factor_year
      factor_revision: $factor_revision
    ) {
      id
    }
  }
`;
export const createSimpleCustomizedProductRequest = /* GraphQL */ `
  query CreateSimpleCustomizedProductRequest(
    $alternative_product_id: String!
    $asset_management_company: String!
    $asset_type: String!
    $client_id: String!
    $intention: String!
    $product_name: String!
  ) {
    CreateSimpleCustomizedProductRequest(
      alternative_product_id: $alternative_product_id
      asset_management_company: $asset_management_company
      asset_type: $asset_type
      client_id: $client_id
      intention: $intention
      product_name: $product_name
    ) {
      _empty
    }
  }
`;
export const duplicatePolicyAssetMixRequest = /* GraphQL */ `
  query DuplicatePolicyAssetMixRequest(
    $new_policy_asset_mix_name: String!
    $policy_asset_mix_id: String!
  ) {
    DuplicatePolicyAssetMixRequest(
      new_policy_asset_mix_name: $new_policy_asset_mix_name
      policy_asset_mix_id: $policy_asset_mix_id
    ) {
      new_policy_asset_mix_id
    }
  }
`;
export const duplicatePortfolioRequest = /* GraphQL */ `
  query DuplicatePortfolioRequest(
    $portfolio_id: String!
    $portfolio_set_id: String!
  ) {
    DuplicatePortfolioRequest(
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
    ) {
      new_portfolio_id
    }
  }
`;

export const duplicatePortfolioWorkspaceRequest = /* GraphQL */ `
  query DuplicatePortfolioWorkspaceRequest(
    $new_workspace_name: String!
    $workspace_id: String!
    $factor_year: Int!
    $factor_revision: Int!
  ) {
    DuplicatePortfolioWorkspaceRequest(
      new_workspace_name: $new_workspace_name
      workspace_id: $workspace_id
      factor_year: $factor_year
      factor_revision: $factor_revision
    ) {
      new_workspace_id
    }
  }
`;
export const freezePolicyAssetMixRequest = /* GraphQL */ `
  query FreezePolicyAssetMixRequest($policy_asset_mix_id: String!) {
    FreezePolicyAssetMixRequest(policy_asset_mix_id: $policy_asset_mix_id) {
      _empty
    }
  }
`;
export const freezePortfolioWorkspaceRequest = /* GraphQL */ `
  query FreezePortfolioWorkspaceRequest($workspace_id: String!) {
    FreezePortfolioWorkspaceRequest(workspace_id: $workspace_id) {
      _empty
    }
  }
`;
export const getAnalysisResult = /* GraphQL */ `
  query GetAnalysisResult(
    $event_probability_for_down_side_risk_analysis: Float!
    $workspace_id: String!
  ) {
    GetAnalysisResult(
      event_probability_for_down_side_risk_analysis: $event_probability_for_down_side_risk_analysis
      workspace_id: $workspace_id
    ) {
      down_side_risk_analysis_result {
        down_side_risk_analysis_result_for_current_portfolio {
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_policy_asset_mix {
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_proposed_portfolios {
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_traditional_assets {
          product_id
          product_name
          stress_scenario_results {
            change_rate
            end_date
            start_date
            stress_scenario_name
          }
        }
      }
      market_fluctuation_analysis_result {
        market_fluctuation_result_for_current_portfolio {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_policy_asset_mix {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_proposed_portfolios {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        product_correlation_matrix_result {
          product_name
          product_correlations {
            correlation
            product_name
          }
        }
      }
    }
  }
`;
export const getAssetTypeMapRequest = /* GraphQL */ `
  query GetAssetTypeMapRequest($client_id: String!) {
    GetAssetTypeMapRequest(client_id: $client_id) {
      asset_type_alias_map {
        alias
        asset_type
      }
      client_id
      product_asset_type_map {
        asset_type_attribute {
          asset_type
          default
        }
        product_id
      }
    }
  }
`;
export const getBusyoNameOfClientIDRequest = /* GraphQL */ `
  query GetBusyoNameOfClientIDRequest($client_id: String!) {
    GetBusyoNameOfClientIDRequest(client_id: $client_id) {
      busyo_name
    }
  }
`;
export const getClientRequest = /* GraphQL */ `
  query GetClientRequest($client_id: String!) {
    GetClientRequest(client_id: $client_id) {
      balance_in_custody
      code_type
      consulting_company
      expected_interest_rate
      funding_rate
      id
      incorporation_form
      industry
      name
      organizer
      permission {
        client_id
        read
        user_id
        write
      }
      recalculation_cycle
      sales_office
      sales_representative
      total_assets
      yearly_benefit
      yearly_installment
    }
  }
`;
export const getPolicyAssetMixRequest = /* GraphQL */ `
  query GetPolicyAssetMixRequest($policy_asset_mix_id: String!) {
    GetPolicyAssetMixRequest(policy_asset_mix_id: $policy_asset_mix_id) {
      asset_compositions {
        asset_type
        benchmark_compositions {
          benchmark_id
          ratio
        }
        ratio
      }
      client_id
      created_at
      id
      name
      start_date
      valid
    }
  }
`;
export const getPortfolioWorkspaceRequest = /* GraphQL */ `
  query GetPortfolioWorkspaceRequest($workspace_id: String!) {
    GetPortfolioWorkspaceRequest(workspace_id: $workspace_id) {
      client_id
      created_at
      creator_user_id
      factor_version {
        revision
        year
      }
      frozen
      id
      name
      policy_asset_mix {
        asset_compositions {
          asset_type
          benchmark_compositions {
            benchmark_id
            ratio
          }
          ratio
        }
        client_id
        created_at
        id
        name
        start_date
        valid
      }
      portfolio_set {
        current_portfolio {
          compositions {
            amount
            asset_type
            product_id
          }
          id
          investment_budget
          name
          selected_for_report
          valid
        }
        frozen
        id
        proposed_portfolios {
          compositions {
            amount
            asset_type
            product_id
          }
          id
          investment_budget
          name
          selected_for_report
          valid
        }
        selected_products {
          asset_type
          index
          intention
          product_id
        }
      }
      report
      updated_at
    }
  }
`;
export const listAssetManagementCompaniesRequest = /* GraphQL */ `
  query ListAssetManagementCompaniesRequest {
    ListAssetManagementCompaniesRequest {
      asset_management_companies
    }
  }
`;
export const listAssetTypesRequest = /* GraphQL */ `
  query ListAssetTypesRequest {
    ListAssetTypesRequest {
      asset_types
    }
  }
`;
export const listClientsRequest = /* GraphQL */ `
  query ListClientsRequest {
    ListClientsRequest {
      clients {
        balance_in_custody
        code_type
        consulting_company
        expected_interest_rate
        funding_rate
        id
        incorporation_form
        industry
        name
        organizer
        permission {
          client_id
          read
          user_id
          write
        }
        recalculation_cycle
        sales_office
        sales_representative
        total_assets
        yearly_benefit
        yearly_installment
      }
    }
  }
`;
export const listIntentionsRequest = /* GraphQL */ `
  query ListIntentionsRequest {
    ListIntentionsRequest {
      intentions
    }
  }
`;
export const listPolicyAssetMixesRequest = /* GraphQL */ `
  query ListPolicyAssetMixesRequest($client_id: String!) {
    ListPolicyAssetMixesRequest(client_id: $client_id) {
      policy_asset_mixes {
        is_referenced_by_workspace
        name
        start_date
        valid
        id
        created_at
        client_id
        asset_compositions {
          asset_type
          benchmark_compositions {
            benchmark_id
            ratio
          }
          ratio
        }
      }
    }
  }
`;
export const listPortfolioWorkspacesRequest = /* GraphQL */ `
  query ListPortfolioWorkspacesRequest($client_id: String!) {
    ListPortfolioWorkspacesRequest(client_id: $client_id) {
      portfolio_workspaces {
        client_id
        created_at
        creator_user_id
        factor_version {
          revision
          year
        }
        frozen
        id
        name
        policy_asset_mix {
          asset_compositions {
            asset_type
            benchmark_compositions {
              benchmark_id
              ratio
            }
            ratio
          }
          client_id
          created_at
          id
          name
          start_date
          valid
        }
        portfolio_set {
          current_portfolio {
            compositions {
              amount
              asset_type
              product_id
            }
            id
            investment_budget
            name
            selected_for_report
            valid
          }
          frozen
          id
          proposed_portfolios {
            compositions {
              amount
              asset_type
              product_id
            }
            id
            investment_budget
            name
            selected_for_report
            valid
          }
          selected_products {
            asset_type
            intention
            product_id
          }
        }
        report
        updated_at
      }
    }
  }
`;
export const listPortfolioWorkspacesSummaryRequest = /* GraphQL */ `
  query ListPortfolioWorkspaceSummariesRequest(
    $client_id: String!
    $filter_by_workspace_name: String!
    $sort_by: SortBy!
    $sort_direction: SortDirection!
    $limit: Int!
    $offset: Int!
    $policy_asset_mix_id: String
    $filter_by_ws_permission: FilterByWsPermission!
    $only_frozen: Boolean
  ) {
    ListPortfolioWorkspaceSummariesRequest(
      client_id: $client_id
      filter_by_workspace_name: $filter_by_workspace_name
      sort_by: $sort_by
      sort_direction: $sort_direction
      limit: $limit
      offset: $offset
      policy_asset_mix_id: $policy_asset_mix_id
      filter_by_ws_permission: $filter_by_ws_permission
      only_frozen: $only_frozen
    ) {
      portfolio_workspaces_summary {
        assigned_busyo
        client_id
        client_name
        creator_user_id
        created_at
        creator_user_name
        frozen
        frozen_date
        id
        name
        policy_asset_mix_id
        policy_asset_mix_name
        read
        updated_at
        write
        total_count
      }
    }
  }
`;

export const listProductsRequest = /* GraphQL */ `
  query ListProductsRequest(
    $resource_id: String!
    $resource_name: ResourceName!
    $year: Int
  ) {
    ListProductsRequest(
      resource_id: $resource_id
      resource_name: $resource_name
      year: $year
    ) {
      benchmarks_for_policy_asset_mix {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      benchmarks_for_simple_customized_product {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      full_customized_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      simple_customized_products {
        alternative_product {
          asset_management_company
          asset_type_attributes {
            asset_type
            default
          }
          benchmark_abbreviation
          benchmark_id
          expected_return
          expected_risk
          id
          intention
          product_name
          supplemental_product_name
          upper_limit
        }
        asset_management_company
        asset_type
        id
        intention
        product_name
      }
      standard_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
    }
  }
`;
export const listProductsRequestWithoutYear = /* GraphQL */ `
  query ListProductsRequest(
    $resource_id: String!
    $resource_name: ResourceName!
  ) {
    ListProductsRequest(
      resource_id: $resource_id
      resource_name: $resource_name
    ) {
      benchmarks_for_policy_asset_mix {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      benchmarks_for_simple_customized_product {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      full_customized_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
      simple_customized_products {
        alternative_product {
          asset_management_company
          asset_type_attributes {
            asset_type
            default
          }
          benchmark_abbreviation
          benchmark_id
          expected_return
          expected_risk
          id
          intention
          product_name
          supplemental_product_name
          upper_limit
        }
        asset_management_company
        asset_type
        id
        intention
        product_name
      }
      standard_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        expected_return
        expected_risk
        id
        intention
        product_name
        supplemental_product_name
        upper_limit
      }
    }
  }
`;
export const listUserPermissionOfClientsRequest = /* GraphQL */ `
  query ListUserPermissionOfClientsRequest(
    $client_ids: [String!]!
    $user_id: String!
  ) {
    ListUserPermissionOfClientsRequest(
      client_ids: $client_ids
      user_id: $user_id
    ) {
      client_permissions {
        client_id
        read
        user_id
        write
      }
    }
  }
`;
export const listUserPermissionOfWorkspacesRequest = /* GraphQL */ `
  query ListUserPermissionOfWorkspacesRequest(
    $user_id: String!
    $workspace_ids: [String!]!
  ) {
    ListUserPermissionOfWorkspacesRequest(
      user_id: $user_id
      workspace_ids: $workspace_ids
    ) {
      workspace_permissions {
        read
        user_id
        workspace_id
        write
      }
    }
  }
`;
export const registerUserRequest = /* GraphQL */ `
  query RegisterUserRequest(
    $email: String!
    $name: String!
    $user_id: String!
  ) {
    RegisterUserRequest(email: $email, name: $name, user_id: $user_id) {
      _empty
    }
  }
`;
export const removePortfolioRequest = /* GraphQL */ `
  query RemovePortfolioRequest(
    $portfolio_id: String!
    $portfolio_set_id: String!
  ) {
    RemovePortfolioRequest(
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
    ) {
      _empty
    }
  }
`;
export const removeProductAssetTypePairRequest = /* GraphQL */ `
  query RemoveProductAssetTypePairRequest(
    $asset_type: String!
    $client_id: String!
    $product_id: String!
  ) {
    RemoveProductAssetTypePairRequest(
      asset_type: $asset_type
      client_id: $client_id
      product_id: $product_id
    ) {
      _empty
    }
  }
`;
export const renamePolicyAssetMixRequest = /* GraphQL */ `
  query RenamePolicyAssetMixRequest(
    $new_policy_asset_mix_name: String!
    $policy_asset_mix_id: String!
  ) {
    RenamePolicyAssetMixRequest(
      new_policy_asset_mix_name: $new_policy_asset_mix_name
      policy_asset_mix_id: $policy_asset_mix_id
    ) {
      _empty
    }
  }
`;
export const renamePortfolioRequest = /* GraphQL */ `
  query RenamePortfolioRequest(
    $new_portfolio_name: String!
    $portfolio_id: String!
    $portfolio_set_id: String!
  ) {
    RenamePortfolioRequest(
      new_portfolio_name: $new_portfolio_name
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
    ) {
      _empty
    }
  }
`;
export const updatePolicyAssetMixStartDateRequest = /* GraphQL */ `
  query UpdatePolicyAssetMixStartDateRequest(
    $policy_asset_mix_id: String!
    $start_date: String!
  ) {
    UpdatePolicyAssetMixStartDateRequest(
      policy_asset_mix_id: $policy_asset_mix_id
      start_date: $start_date
    ) {
      _empty
    }
  }
`;
export const updatePortfolioAmountRequest = /* GraphQL */ `
  query UpdatePortfolioAmountRequest(
    $amount: Float!
    $asset_type: String!
    $portfolio_id: String!
    $portfolio_set_id: String!
    $product_id: String!
  ) {
    UpdatePortfolioAmountRequest(
      amount: $amount
      asset_type: $asset_type
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
      product_id: $product_id
    ) {
      valid
    }
  }
`;
export const updatePortfolioInvestmentBudgetRequest = /* GraphQL */ `
  query UpdatePortfolioInvestmentBudgetRequest(
    $investment_budget: Float!
    $portfolio_id: String!
    $portfolio_set_id: String!
  ) {
    UpdatePortfolioInvestmentBudgetRequest(
      investment_budget: $investment_budget
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
    ) {
      valid
    }
  }
`;
export const updateReportRequest = /* GraphQL */ `
  query UpdateReportRequest($report: String!, $workspace_id: String!) {
    UpdateReportRequest(report: $report, workspace_id: $workspace_id) {
      _empty
    }
  }
`;

export const updateWorkspaceNameRequest = /* GraphQL */ `
  query UpdateWorkspaceNameRequest(
    $new_workspace_name: String!
    $workspace_id: String!
  ) {
    UpdateWorkspaceNameRequest(
      new_workspace_name: $new_workspace_name
      workspace_id: $workspace_id
    ) {
      _empty
    }
  }
`;

export const updateSelectedForReportRequest = /* GraphQL */ `
  query UpdateSelectedForReportRequest(
    $portfolio_id: String!
    $portfolio_set_id: String!
    $selected_for_report: Boolean!
  ) {
    UpdateSelectedForReportRequest(
      portfolio_id: $portfolio_id
      portfolio_set_id: $portfolio_set_id
      selected_for_report: $selected_for_report
    ) {
      _empty
    }
  }
`;
export const updateSelectedProductsRequest = /* GraphQL */ `
  query UpdateSelectedProductsRequest(
    $selected_products: [SelectedProductInput!]!
    $workspace_id: String!
  ) {
    UpdateSelectedProductsRequest(
      selected_products: $selected_products
      workspace_id: $workspace_id
    ) {
      _empty
    }
  }
`;
export const updateUserRequest = /* GraphQL */ `
  query UpdateUserRequest(
    $admin: Boolean!
    $busyo_id: String!
    $email: String!
    $name: String!
    $super_viewer: Boolean!
    $user_id: String!
  ) {
    UpdateUserRequest(
      admin: $admin
      busyo_id: $busyo_id
      email: $email
      name: $name
      super_viewer: $super_viewer
      user_id: $user_id
    ) {
      _empty
    }
  }
`;
export const upsertAssetCompositionRequest = /* GraphQL */ `
  query UpsertAssetCompositionRequest(
    $asset_composition: AssetCompositionInput
    $policy_asset_mix_id: String!
  ) {
    UpsertAssetCompositionRequest(
      asset_composition: $asset_composition
      policy_asset_mix_id: $policy_asset_mix_id
    ) {
      _empty
    }
  }
`;
export const upsertAssetRatioRequest = /* GraphQL */ `
  query UpsertAssetRatioRequest(
    $asset_type_ratios: [AssetTypeRatio!]!
    $policy_asset_mix_id: String!
  ) {
    UpsertAssetRatioRequest(
      asset_type_ratios: $asset_type_ratios
      policy_asset_mix_id: $policy_asset_mix_id
    ) {
      _empty
    }
  }
`;
export const upsertAssetTypeAliasRequest = /* GraphQL */ `
  query UpsertAssetTypeAliasRequest(
    $alias: String!
    $asset_type: String!
    $client_id: String!
  ) {
    UpsertAssetTypeAliasRequest(
      alias: $alias
      asset_type: $asset_type
      client_id: $client_id
    ) {
      _empty
    }
  }
`;

export const getAnalysisResultRequest = /* GraphQL */ `
  query GetAnalysisResultRequest($workspace_id: String!) {
    GetAnalysisResultRequest(
      event_probability_for_down_side_risk_analysis: 0.01
      workspace_id: $workspace_id
    ) {
      down_side_risk_analysis_result {
        down_side_risk_analysis_result_for_current_portfolio {
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_policy_asset_mix {
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_proposed_portfolios {
          id
          name
          stress_scenario_results {
            end_date
            start_date
            stress_scenario_name
            risk_analysis_result {
              change_rate
              change_in_total_asset
            }
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_traditional_assets {
          stress_scenario_results {
            change_rate
            start_date
            end_date
            stress_scenario_name
          }
          traditional_asset_name
        }
      }
      market_fluctuation_analysis_result {
        market_fluctuation_result_for_current_portfolio {
          name
          id
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_policy_asset_mix {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_proposed_portfolios {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
      }
      workspace_risk_return_result {
        portfolio_risk_return {
          risk_return_by_asset_type {
            risk_by_asset_type {
              asset_type_risks {
                asset_type
                risk
              }
              disposition_effect
              sum_of_risks
            }
            return_by_asset_type {
              asset_type_returns {
                asset_type
                return
              }
              sum_of_returns
            }
          }
          risk_return_by_factor_category {
            return_by_factor_main_category {
              category_returns {
                category
                index
                return
              }
              sum_of_returns
            }
            return_by_factor_sub_category {
              category_category_returns {
                category
                category_returns {
                  category
                  index
                  return
                }
                index
              }
              sum_of_returns
            }
            risk_by_factor_main_category {
              category_risks {
                category
                index
                risk
              }
              disposition_effect
              sum_of_risks
            }
            risk_by_factor_sub_category {
              category_category_risks {
                category
                category_risks {
                  category
                  index
                  risk
                }
                index
              }
              disposition_effect
              sum_of_risks
            }
          }
          portfolio_id
          portfolio_name
          return
          risk
        }
        pam_risk_return {
          policy_asset_mix_id
          policy_asset_mix_name
          return
          risk
          risk_return_by_asset_type {
            return_by_asset_type {
              asset_type_returns {
                asset_type
                return
              }
              sum_of_returns
            }
            risk_by_asset_type {
              asset_type_risks {
                asset_type
                risk
              }
              disposition_effect
              sum_of_risks
            }
          }
          risk_return_by_factor_category {
            return_by_factor_main_category {
              category_returns {
                category
                index
                return
              }
              sum_of_returns
            }
            return_by_factor_sub_category {
              category_category_returns {
                category
                category_returns {
                  category
                  index
                  return
                }
                index
              }
              sum_of_returns
            }
            risk_by_factor_main_category {
              category_risks {
                category
                index
                risk
              }
              disposition_effect
              sum_of_risks
            }
            risk_by_factor_sub_category {
              category_category_risks {
                category
                category_risks {
                  category
                  index
                  risk
                }
                index
              }
              disposition_effect
              sum_of_risks
            }
          }
        }
      }
      product_correlation_matrix_result {
        asset_type
        product_name
        index
        product_correlations {
          asset_type
          correlation
          index
          product_name
        }
      }
    }
  }
`;

export const getPortfolioLevelRiskReturnRequest = /* GraphQL */ `
  query GetAnalysisResultRequest($workspace_id: String!) {
    GetAnalysisResultRequest(
      event_probability_for_down_side_risk_analysis: 0.01
      workspace_id: $workspace_id
    ) {
      workspace_risk_return_result {
        pam_risk_return {
          policy_asset_mix_id
          policy_asset_mix_name
          return
          risk
        }
        portfolio_risk_return {
          portfolio_id
          portfolio_name
          risk
          return
        }
      }
    }
  }
`;

export const getDownsideRiskAnalysisResultRequest = /* GraphQL */ `
  query GetAnalysisResultRequest($workspace_id: String!) {
    GetAnalysisResultRequest(
      event_probability_for_down_side_risk_analysis: 0.01
      workspace_id: $workspace_id
    ) {
      down_side_risk_analysis_result {
        down_side_risk_analysis_result_for_current_portfolio {
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_policy_asset_mix {
          id
          name
          stress_scenario_results {
            end_date
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
            start_date
            stress_scenario_name
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_proposed_portfolios {
          id
          name
          stress_scenario_results {
            end_date
            start_date
            stress_scenario_name
            risk_analysis_result {
              change_rate
              change_in_total_asset
            }
          }
          cvar_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
          var_1yr_99_percent {
            change_in_total_asset
            change_rate
          }
        }
        down_side_risk_analysis_result_for_traditional_assets {
          stress_scenario_results {
            change_rate
            start_date
            end_date
            stress_scenario_name
          }
          traditional_asset_name
        }
      }
    }
  }
`;

export const getMarketFluctuationAnalysisResultRequest = /* GraphQL */ `
  query GetAnalysisResultRequest($workspace_id: String!) {
    GetAnalysisResultRequest(
      event_probability_for_down_side_risk_analysis: 0.01
      workspace_id: $workspace_id
    ) {
      market_fluctuation_analysis_result {
        market_fluctuation_result_for_current_portfolio {
          name
          id
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_policy_asset_mix {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
        market_fluctuation_result_for_proposed_portfolios {
          id
          name
          result_with_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
          result_without_correlation {
            factor_scenario_name
            risk_analysis_result {
              change_in_total_asset
              change_rate
            }
          }
        }
      }
    }
  }
`;

export const getProductCorrelationMatrixResultRequest = /* GraphQL */ `
  query GetAnalysisResultRequest($workspace_id: String!) {
    GetAnalysisResultRequest(
      event_probability_for_down_side_risk_analysis: 0.01
      workspace_id: $workspace_id
    ) {
      product_correlation_matrix_result {
        product_name
        product_correlations {
          correlation
          product_name
        }
      }
    }
  }
`;

export const listDefaultBMForAssetTypesRequest = /* GraphQL */ `
  query ListDefaultBMForAssetTypesRequest {
    ListDefaultBMForAssetTypesRequest {
      default_bm_for_asset_types {
        asset_type
        product_id
        ratio
      }
    }
  }
`;

export const listClientSummariesRequest = /* GraphQL */ `
  query ListClientSummariesRequest {
    ListClientSummariesRequest {
      client_summaries {
        assigned_busyo
        assigned_user_name
        id
        latest_full_customized_product_created_at
        latest_pam_start_date
        latest_simple_customized_product_created_at
        name
        number_of_full_customized_products
        number_of_simple_customized_products
        number_of_workspaces
        number_of_workspaces_with_latest_pam
      }
    }
  }
`;

export const updateSimpleCustomizedProductRequest = /* GraphQL */ `
  query UpdateSimpleCustomizedProductRequest(
    $client_id: String!
    $new_product_name: String!
    $product_id: String!
  ) {
    UpdateSimpleCustomizedProductRequest(
      client_id: $client_id
      new_product_name: $new_product_name
      product_id: $product_id
    ) {
      _empty
    }
  }
`;

export const deletePortfolioWorkspaceRequest = /* GraphQL */ `
  query DeletePortfolioWorkspaceRequest($workspace_id: String!) {
    DeletePortfolioWorkspaceRequest(workspace_id: $workspace_id) {
      _empty
    }
  }
`;

export const listFactorVersionsRequest = /* GraphQL */ `
  query ListFactorVersionsRequest {
    ListFactorVersionsRequest {
      factor_versions {
        revision
        year
      }
    }
  }
`;

export const getQuicksightDashboardEmbedUrlRequest = /* GraphQL */ `
  query GetQuicksightDashboardEmbedUrlRequest($email: String!) {
    GetQuicksightDashboardEmbedUrlRequest(email: $email) {
      quicksight_dashboard_embed_url
    }
  }
`;

export const getNonExistentProductsInPortfolioWorkspaceRequest = /* GraphQL */ `
  query GetNonExistentProductsInPortfolioWorkspaceRequest(
    $workspace_id: String!
    $factor_revision: Int!
    $factor_year: Int!
  ) {
    GetNonExistentProductsInPortfolioWorkspaceRequest(
      workspace_id: $workspace_id
      factor_revision: $factor_revision
      factor_year: $factor_year
    ) {
      non_existent_products {
        asset_type
        product_id
        product_name
      }
      non_existent_benchmarks {
        asset_type
        product_id
        product_name
      }
    }
  }
`;
